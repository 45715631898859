<template>
  <transition
    enter-active-class="alertEnter animated fadeIn faster"
    leave-active-class="alertLeave animated fadeOut faster"
    appear
    mode="out-in"
  >
    <div class="alert-parent" :class="dynamicClasses">
      <div class="text animated flash infinite">
        <!-- ICON -->
        <div class="text-icon">
          <i
            v-if="taskObject.data.type === 'subscription' && isHighTier"
            class="fa fa-star mb-3"
          ></i>
          <i v-if="taskObject.data.type === 'subgift'" class="fa fa-gift mb-3"></i>
          <i v-if="taskObject.data.type === 'submysterygift'" class="fa fa-gift mb-3 mx-2"></i>
          <i v-if="taskObject.data.type === 'submysterygift'" class="fa fa-gift mb-3 mx-2"></i>
          <i v-if="taskObject.data.type === 'submysterygift'" class="fa fa-gift mb-3 mx-2"></i>
          <i v-if="taskObject.data.type === 'resub'" class="fa fa-birthday-cake mb-3"></i>
          <i v-if="taskObject.data.type === 'donation'" class="fa fa-money mb-3"></i>
        </div>

        <!-- USER DISPLAY NAME -->
        <div class="text-display-name">
          {{ userDisplayName }}
        </div>

        <!-- DONATION -->
        <div v-if="subType === 'donation'" class="text-desc">
          HAS DONATED
          <span style="color: lime;"
            >{{ donationAmount }} <small class="text-white">USD</small></span
          >
        </div>

        <!-- NEW SUBSCRIPTION (FIRST TIME) -->
        <div v-if="subType === 'subscription' && totalCumulativeMonths === 1" class="text-desc">
          HAS SUBSCRIBED <span class="text-primary">{{ subTierFormatted }}</span>
        </div>

        <!-- SUBSCRIPTION (AFTER BREAK) -->
        <div v-if="subType === 'subscription' && totalCumulativeMonths >= 2" class="text-desc">
          HAS SUBSCRIBED <span class="text-primary">{{ subTierFormatted }}</span> FOR
          <span class="text-primary pr-2">{{ totalCumulativeMonths }} MONTHS</span>
        </div>

        <!-- RESUB (KEPT IT GOING) -->
        <div v-if="subType === 'resub'" class="text-desc">
          RE-SUBBED <span class="text-primary">{{ subTierFormatted }}</span> FOR
          <span class="text-primary pr-2">{{ totalCumulativeMonths }} MONTHS</span>
          <span v-if="totalStreakMonths">({{ totalStreakMonths }}x)</span>
        </div>

        <!-- (ANON) GIFT PAID UPGRADE -->
        <div
          v-if="subType === 'giftpaidupgrade' || subType === 'anongiftpaidupgrade'"
          class="text-desc"
        >
          CONTINUED GIFT SUB <span class="text-primary">{{ subTierFormatted }}</span>
          <span v-if="totalCumulativeMonths >= 2">
            FOR
            <span class="text-primary">{{ totalCumulativeMonths }} MONTHS</span>
          </span>
        </div>

        <!-- SUBGIFT -->
        <div v-if="subType === 'subgift'" class="text-desc">
          HAS GIFTED
          <span v-if="subTierFormatted">
            <span class="text-primary">{{ subTierFormatted }} TO</span>
          </span>
          <span class="text-primary text-uppercase">{{ recipientDisplayName }}</span>
        </div>

        <!-- SUBMYSTERYGIFT -->
        <div v-if="subType === 'submysterygift'" class="text-desc">
          HAS GIFTED
          <span class="text-primary">{{ totalGiftSubs }}</span>
          <span class="text-primary"> {{ subTierFormatted }} </span>
          COMMUNITY SUBS
        </div>

        <!-- end -->
      </div>

      <!-- CHEER? -->

      <!-- CHUCKY SPRITE -->
      <transition
        enter-active-class="chuckyEnter animated slideInUp"
        leave-active-class="chuckyLeave animated slideOutDown"
        appear
      >
        <div class="chucky-sprite">
          <img src="./assets/img/chucky-standing.png" alt="Chucky Waving" />
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
  export default {
    props: {
      taskObject: {
        type: Object,
        required: true,
      },
    },

    //------------------------------------------------------------
    // COMPUTED
    //
    // https://dev.twitch.tv/docs/irc/tags/#prototype-6
    // what is a resub? https://discuss.dev.twitch.com/t/why-does-eventsub-send-channel-subscribe-events-for-resubs-when-the-documentation-says-it-does-not/43781
    // WHAT IS A RESUB?
    // "A resub is an announcement of a continued sub without a break in the middle."
    //------------------------------------------------------------

    computed: {
      donationAmount() {
        return this.taskObject.data.amount;
      },
      dynamicClasses() {
        return {
          isAnonGiftUpgrade: this.subType === "anongiftpaidupgrade",
          isDono: this.subType === "donation",
          isPrime: this.taskObject.data.userstate?.["msg-param-sub-plan"] === "Prime",
          isGiftPaidUpgrade: this.subType === "giftpaidupgrade",
          isResub: this.subType === "resub",
          isSub: this.subType === "subscription",
          isSubGift: this.subType === "subgift",
          isSubMysteryGift: this.subType === "submysterygift",
        };
      },
      userDisplayName() {
        return this.taskObject.data.userstate?.["display-name"] || this.taskObject.data.arg;
      },
      recipientDisplayName() {
        return this.taskObject.data.userstate?.["msg-param-recipient-display-name"];
      },
      subTierFormatted() {
        const tierMap1 = {
          1000: "", // base is irrelevant
          2000: "TIER 2",
          3000: "TIER 3",
          Prime: "WITH PRIME",
        };
        const subPlan = this.taskObject.data.userstate?.["msg-param-sub-plan"];
        return tierMap1[subPlan];
      },
      isHighTier() {
        const isTier2 = this.subTierFormatted.includes("TIER 2");
        const isTier3 = this.subTierFormatted.includes("TIER 3");
        const isPrime = this.subTierFormatted.includes("PRIME");
        return isTier2 || isTier3 || isPrime;
      },
      subType() {
        return this.taskObject.data.type;
      },
      totalGiftSubs() {
        return Number(this.taskObject.data.numbOfSubs);
      },
      totalCumulativeMonths() {
        return Number(this.taskObject.data.userstate?.["msg-param-cumulative-months"]);
      },
      totalStreakMonths() {
        const { userstate } = this.taskObject.data;
        const isStreakShared = !!userstate?.["msg-param-should-share-streak"];
        const streakMonths = Number(userstate?.["msg-param-streak-months"]);
        return isStreakShared && streakMonths;
      },
    },

    //------------------------------------------------------------
    // MOUNTED
    //------------------------------------------------------------

    mounted() {
      this.$playSound("chucky-laugh", { interrupt: true, volume: 2.0 }); // PLAY SOUND

      console.log(this.taskObject?.data);

      // resubs and donations don't count towards new subs.
      if (
        this.taskObject.data.type !== "resub" &&
        this.taskObject.data.type !== "donation" &&
        this.taskObject.data.type !== "anongiftpaidupgrade" &&
        this.taskObject.data.type !== "giftpaidupgrade"
      ) {
        const totalSubs = this.totalGiftSubs || 1;

        // Increment sub total
        this.$store.dispatch("twitch/addSubscriberTotal", totalSubs);
      }

      this.$store.dispatch("tasks/runTasksRemotely", [
        { uuid: "bb5398fa-660b-44e6-85d4-5720bf9879f4" }, // long static
        { uuid: "156ccfdd-d055-4b7b-b695-c917641ddfde", canceled: false }, // hide posters
        { uuid: "c388392f-1931-4481-8c7e-99a0755b642a", canceled: false }, // pause media poster
      ]);
    },

    beforeDestroy() {
      // will this be chaotic??
      this.$store.dispatch("tasks/runTasksRemotely", [
        { uuid: "156ccfdd-d055-4b7b-b695-c917641ddfde", canceled: true }, // hide posters
        // { uuid: 'c388392f-1931-4481-8c7e-99a0755b642a', canceled: true } // pause media poster
      ]);
    },
  };
</script>

<style lang="scss" scoped>
  .alert-parent {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding-bottom: rem-calc(370);
    background: rgba(#f14643, 0.8);
    // border: 4px dashed yellow;
    z-index: 2;
  }

  //-----------------------------------------------------------------
  // TEXT
  //-----------------------------------------------------------------

  .text {
    font-family: "Avenir Next Condensed";
    font-size: rem-calc(760);
    font-weight: 600;
    line-height: 0.9;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0 20px 60px rgba(black, 0.5);
    color: white;
    // border: 1px dashed yellow;
  }

  .text-icon {
    font-size: rem-calc(80);
    animation: shake 0.5s infinite;
  }

  .text-display-name {
    font-size: rem-calc(140);
    animation: shake 0.5s infinite;
    margin-bottom: rem-calc(20);
  }

  .text-desc {
    font-size: rem-calc(55);
    color: rgba(white, 0.8);
    text-transform: none;
    animation: shake 0.5s infinite;
  }

  //-----------------------------------------------------------------
  // CHUCKY
  //-----------------------------------------------------------------

  .chuckyEnter {
    animation-timing-function: ease;
    animation-duration: 800ms;
  }

  .chucky-sprite {
    position: absolute;
    bottom: rem-calc(-440);
    z-index: -1;

    img {
      animation: shake 0.5s infinite;
    }
  }

  .is-gift .chucky-sprite,
  .is-resub .chucky-sprite {
    bottom: rem-calc(-550);
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
</style>
